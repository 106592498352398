<template >
  <div>
    <div class="app-modal__box">
      <div class="app-modal__in">
        <div class="app-modal__header d-flex f-between p-5 m-title-modal">
          <p class="large--title m-0"> {{
              $t("message.new_m", {
                m: $t("message.phoneNumberForSipAccountOutsideCall"),
              })
            }}</p>
          <div>
             <crm-store-update-close
                :permission="$options.name"
                :button_type="'store'"
                :loading="loadingButton"
                @c-submit="submit(true)"
                @c-close="close()"
            ></crm-store-update-close>
          </div>
        </div>
      </div>
      <!-- app-modal__header end -->

      <div class="app-modal__body p-5 pb-0">
        <div class="timeline-items__right rounded-sm w-100 p-4">
            <el-form ref="form" :model="form" :rules="rules" label-position="top">
                <el-row :gutter="20">
                    <el-col :span="24">
                      <el-form-item
                      prop="name"
                      :label="$t('message.name')"
                      >
                        <el-input
                            :placeholder="$t('message.name')"
                            v-model="form.name"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="24">
                      <el-form-item
                      prop="prefix"
                      :label="$t('message.prefix')"
                      >
                        <el-input
                            :placeholder="$t('message.prefix')"
                            v-model="form.prefix"
                            type="number"
                            size="medium"
                        ></el-input>
                      </el-form-item>     
                    </el-col>
                    <!-- end-col -->

                    <el-col :span="24">
                        <crm-phone-input
                            :inputValue="form.phone_number" 
                            v-model="form.phone_number"
                            @getPhoneStatus="getPhoneStatus"
                        ></crm-phone-input>
                    </el-col>
                    <!-- end-col -->

                </el-row>
            </el-form>
        </div>
      </div>
      <!-- end app-modal__body -->
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from "vuex";
import drawer from "@/utils/mixins/drawer";
import form from "@/utils/mixins/form";

export default {
  mixins: [form, drawer],
  data() {
    return {
      is_phone_valid: false
    };
  },
  created() {},
  computed: {
      ...mapGetters({
          rules: "phoneNumberForSipAccountOutsideCall/rules",
          model: "phoneNumberForSipAccountOutsideCall/model",
          columns: "phoneNumberForSipAccountOutsideCall/columns",
      }),
  },
  methods: {
      ...mapActions({
          save: "phoneNumberForSipAccountOutsideCall/store",
      }),
      getPhoneStatus(is_valid){
        this.is_phone_valid = is_valid;
      },
      submit(close = true) {
          this.$refs["form"].validate((valid) => {
              if (valid) {
                if(this.is_phone_valid){
                  this.loadingButton = true;
                  this.save(this.form)
                      .then((res) => {
                          this.loadingButton = false;
                          this.$alert(res);
                          this.parent().listChanged();
                          if (close) this.close();
                      })
                      .catch((err) => {
                          this.loadingButton = false;
                          this.$alert(err);
                      });
                }else{
                  this.$notify({
                      title: this.$t('message.validation'),
                      type: "error",
                      offset: 130,
                      message: this.$t('message.phone_number_is_not_valid')
                  });
                }
                  
              }
          });
      },
  },
};
</script>
